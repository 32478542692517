import {graphql} from 'gatsby';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';

import {HeaderIndex, Layout, PostView} from '../components/common';
import {
    OverlayContainer,
    StickyNavContainer,
} from '../components/common/effects';
import {MetaData} from '../components/common/meta';
import FeaturedTags from '../components/extra/FeaturedTags';
import NewsView from '../components/extra/NewsView';
import {GlobalStateContext} from '../context/GlobalState';

/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost
 *
 */
function getNewsFragment(siteConfig, pageContext, posts) {
    const home = siteConfig.layout.home;
    return posts.length > 0 && home.news.shown ? (
        <NewsView
            title={home.news.title}
            posts={posts}
            pageContext={pageContext}
        />
    ) : (
        ``
    );
}

function getFeaturedTagsFragment(siteConfig, collectionPath) {
    const home = siteConfig.layout.home;
    return home.featuredTags.shown &&
        home.featuredTags.slugs &&
        home.featuredTags.slugs.length > 0 ? (
        <FeaturedTags
            title={home.featuredTags.title}
            collectionPath={collectionPath}
            tags={home.featuredTags.slugs}
        />
    ) : (
        ``
    );
}

function getLatestPostsFragment(siteConfig, globalState, pageContext, posts) {
    const home = siteConfig.layout.home;
    return posts.length > 0 && home.latestPosts.shown ? (
        <div className="latest-posts">
            <h2 className="inner home-section-title">
                {home.latestPosts.title}
            </h2>
            <PostView
                globalState={globalState}
                pageContext={pageContext}
                posts={posts}
                isHome={true}
            />
        </div>
    ) : (
        ``
    );
}

const Index = ({data, location, pageContext}) => {
    const parsedQuery =
        location.search &&
        location.search.length > 0 &&
        queryString.parse(location.search);
    const posts = data.allGhostPost.edges;
    const news = data.news.edges;
    const isCollection = pageContext.collectionPath !== `/`;
    const config = data.site.siteMetadata;
    const home = config.layout.home;
    //console.log(`*********** INDEX TAG TREE: ${JSON.stringify(pageContext.tagTree)}`);
    return (
        <GlobalStateContext.Consumer>
            {(g) => (
                <React.Fragment>
                    <MetaData location={location} image={data.file} />
                    <StickyNavContainer
                        throttle={300}
                        activeClass="fixed-nav-active"
                        render={(sticky) => (
                            <OverlayContainer
                                render={(overlay) => (
                                    <Layout
                                        parsedQuery={
                                            parsedQuery !== ``
                                                ? parsedQuery
                                                : undefined
                                        }
                                        isHome={true}
                                        header={
                                            <HeaderIndex overlay={overlay} />
                                        }
                                        sticky={sticky}
                                        overlay={overlay}
                                    >
                                        {!isCollection
                                            ? getFeaturedTagsFragment(
                                                  config,
                                                  pageContext.collectionPath,
                                              )
                                            : ``}
                                        {!isCollection
                                            ? getNewsFragment(
                                                  config,
                                                  pageContext,
                                                  news,
                                              )
                                            : ``}
                                        {getLatestPostsFragment(
                                            config,
                                            g,
                                            pageContext,
                                            posts,
                                        )}
                                    </Layout>
                                )}
                            />
                        )}
                    />
                </React.Fragment>
            )}
        </GlobalStateContext.Consumer>
    );
};

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
        file: PropTypes.object,
    }).isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
};

export default Index;

// This page query loads all posts
// Note that sorting within $postIds is irrelevant!
export const pageQuery = graphql`
    query GhostPostQuery($postIds: [String!]!, $limit: Int!, $skip: Int!) {
        site {
            siteMetadata {
                ...SiteMetadataFields
            }
        }
        news: allGhostPost(
            filter: {tags: {elemMatch: {slug: {eq: "hash-news"}}}}
            limit: 4
            sort: {fields: [featured, published_at], order: [DESC, DESC]}
        ) {
            edges {
                node {
                    ...GhostPostFieldsForIndex
                }
            }
        }
        allGhostPost(
            filter: {id: {in: $postIds}}
            limit: $limit
            skip: $skip
            sort: {fields: [featured, published_at], order: [DESC, DESC]}
        ) {
            edges {
                node {
                    ...GhostPostFieldsForIndex
                }
            }
        }
        file(relativePath: {eq: "site-meta.png"}) {
            publicURL
            imageMeta {
                width
                height
            }
        }
    }
`;
