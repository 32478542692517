// © 2021 Ambrose Software Inc. All rights reserved.

import {StaticQuery, graphql} from 'gatsby';
import React from 'react';

import {GhostTagEdge} from '../../types';
import TagCard from './TagCard';

type Props = {
    title: string;
    collectionPath: string;
    tags: string[];
};

const FeaturedTags = ({
    data,
    title,
    collectionPath,
    tags,
}: {data: any} & Props): React.ReactElement => {
    const ghostTags: GhostTagEdge[] = data.allGhostTag.edges;
    return (
        <div className="featured-tags">
            {title ? <h2 className="inner home-section-title">{title}</h2> : ``}
            <div className="inner tag-cards">
                {tags.map((tag) => {
                    const ghostTag = ghostTags.find(
                        (ghostTag) => ghostTag.node.slug === tag,
                    );
                    return (
                        <TagCard
                            collectionPath={collectionPath}
                            tag={ghostTag.node}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const FeaturedTagsQuery = (props: Props): React.ReactElement => (
    <StaticQuery
        query={graphql`
            query FeaturedTagsGhostSettings {
                allGhostTag {
                    edges {
                        node {
                            ...GhostTagFields
                        }
                    }
                }
            }
        `}
        render={(data): React.ReactElement => (
            <FeaturedTags data={data} {...props} />
        )}
    />
);

export default FeaturedTagsQuery;
